.q-textarea {
    padding: 1.3rem !important;
    border: unset !important;
    border-color: none !important;
    border-radius: 15px !important;
    font-family: Open Sans;
    font-size: 1rem !important;
    caret-color: var(--cyan-color) !important;
    /* user-selec; */
}

textarea.q-textarea:focus {
    outline: none !important;
    border: 1px solid #000 !important;
}