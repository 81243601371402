.form-wrapper {
    position: relative;
}

.form-wrapper .title-container .line {
    width: 100% !important;
    height: 1px !important;
    background: #b6b6b6;
    top: 45px !important;
    right: 100% !important;
    left: -3% !important;
    /* transform: translateX(-50%); */
    position: absolute;
    bottom: 0 !important;
}