.number-group {
    border: none;
    padding: 0 0 2px 0;
    flex-wrap: nowrap;
    gap: 15px;
    margin-inline-start: 0;
    align-items: center;
    display: flex;
}

.number-group .number-box {
    position: relative;
    border-radius: 10px;
    white-space: nowrap;
    padding: 0;
    width: 50px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-width: auto;
    text-align: center;
    border: 0px solid rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0.91);
    fill: rgba(0, 0, 0, 0.91);
    font-size: 16px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    transition: border 0.2s ease;
    cursor: pointer;
    padding: 0 2rem;
}

.number-group .number-wrap {
    font-weight: inherit;
    border: none;
}

.number-group .numselected {
    /* background-color: var(--black-color); */
    border: 2px solid var(--black-color);
    /* color: white !important; */
    box-shadow: 0px 1px 2px 0px hsla(0, 0%, 0%, 0.15);
}

.number-group .number-box .check-icon::after {
    position: absolute;
    top: -8px;
    right: -8px;
    content: "";
    width: 1rem;
    height: 1rem;
    background: black;
    z-index: 0;
    border-radius: 5px;
}

.number-group .number-box .check-icon::before {
    position: absolute;
    top: -6px;
    right: -6px;
    content: "";
    width: 0.5em;
    height: 0.5rem;
    clip-path: polygon(12.49% 50.79%, 18.5% 37.5%, 42.25% 61.25%, 88.13% 15.38%, 94.5% 30%, 42.25% 82.25%, 12.49% 50.79%);
    transform: scale(0);
    background-color: #fff;
    z-index: 1;
}

.number-group .numselected .check-icon::before {
    transform: scale(1);
    border: 0.15rem solid #fff;
}