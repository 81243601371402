.yes-no-switch {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.yes-no-switch input[type="radio"] {
    display: none;
}

.yes-no-switch label {
    margin-right: 10px;
    font-weight: bold;
    border-radius: 25px;
    border: 1px solid #000;
    padding: 0.3rem 1rem;
    cursor: pointer;
}

.yes-no-switch input[type="radio"]:checked+label {
    background: #000 !important;
    color: #fff;
}

.yes-no-switch input[type="radio"]:checked+label:hover {
    background: #000;
    color: #fff;
}

.yes-no-switch input[type="radio"]+label:hover {
    background: var(--light-grey);
    transition: all 0.3s ease;
}