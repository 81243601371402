footer {
  min-height: 20vh;
  background-color: var(--black-color);
  position: relative;
}

footer .container {
  padding: 1rem 0;
}

footer .container .footer-menu {
  display: flex;
  justify-content: space-between;
  place-items: center;
  text-transform: capitalize;
  font-size: 0.9rem;
  padding: 1rem 0;
  color: var(--light-color);
}

footer .container .footer-menu .copyright {
  /* width: 25%; */
  justify-content: center;
  flex: 1;
  text-align: center;
}

footer .container .footer-menu .copyright .copy-name {
  color: var(--light-color);
}

footer .container .footer-menu .copyright .copy-name a {
  color: var(--light-color);
}

footer .container .footer-menu .copyright .top ul {
  display: flex;
  gap: 5%;
}

footer .container .footer-menu .copyright .top ul li a {
  color: var(--light-color);
}

footer .container .footer-menu .copyright .bottom li a {
  color: var(--light-color);
}

footer .container .footer-menu .menu-items {
  /* width: 55%; */
  display: flex;
  flex: 4;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 5%;
  place-items: center;
  /* border: solid red 2px; */
}

footer .container .footer-menu .menu-items .menu_item {
  color: var(--light-color);
  /* border: solid red 2px; */
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width: 768px) {
  footer {
    padding-bottom: 6rem;
  }

  footer .container {
    padding: 3rem 0;
  }

  footer .container .footer-menu {
    margin-bottom: 4rem;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  footer .container .footer-menu .copyright {
    width: 100%;
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
    text-align: justify;
  }

  footer .container .footer-menu .copyright .copy-name {
    text-align: center;
  }

  footer .container .footer-menu .copyright .top ul {
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0.5rem 0;
  }

  footer .container .footer-menu .copyright .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  footer .container .footer-menu .menu-items {
    /* display: none; */
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  footer .container .footer-menu .menu-items a {
    margin-bottom: 1rem;
  }
}

/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width: 600px) {
  footer {
    padding-bottom: 6rem;
  }

  footer .container {
    padding: 3rem 0;
  }

  footer .container .footer-menu {
    margin-bottom: 4rem;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  footer .container .footer-menu .copyright {
    width: 100%;
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
    text-align: justify;
  }

  footer .container .footer-menu .copyright .copy-name {
    text-align: center;
  }

  footer .container .footer-menu .copyright .top ul {
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0.5rem 0;
  }

  footer .container .footer-menu .copyright .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  footer .container .footer-menu .menu-items {
    /* display: none; */
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  footer .container .footer-menu .menu-items a {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 400px) {
  footer {
    min-height: 50vh !important;
    padding-bottom: 6rem;
  }
}