.image-selector {
    padding: 0 0.5rem;
    align-items: center;
}

.image-option {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border: 2px solid transparent;
    padding: 10px;
    border-radius: 10px;
    transition: border-color 0.3s ease, transform 0.3s ease;
    margin-bottom: 15px;
}

.image-option .service-check-option {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
}

.image-option.selected {
    border-color: #000;
    transform: scale(1.05);
}

.image-option img {
    width: 100px;
    height: 100px;
}

.image-selector .col-md-4:nth-child(2) .image-option img {
    width: 170px;
    height: 90px;
    padding-top: 1rem;
    margin-bottom: 7px;
}

.option-label {
    font-size: 15px;
    text-align: center;
    font-style: italic;
}