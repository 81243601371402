@import url("https://rawcdn.githack.com/DanielGraham123/bstyles/6ebacfe6396fd863cdcdb87ab1ea84c0ebd407b0/bstyles.css");

#qn {
    height: 100vh;
    overflow-x: hidden;
}

#qn::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    background-color: transparent;
}

#qn::-webkit-scrollbar-thumb {
    background-color: var(--light-color);
    border-radius: 15px;

}

.qn-sidebar {
    position: fixed;
    width: 410px;
    height: 100vh;
    top: 0;
    bottom: 0;
    overflow: hidden;
    left: 4%
}

.form-step {
    padding-left: 30.14rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}

#qn.container {
    position: relative;
    padding-bottom: 6rem;
    padding-top: 2rem;
}

#qn .logo {
    width: 206px;
    height: 80px;
}

#qn h2 {
    font-size: 25px;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    color: #000;
    text-transform: capitalize;
}

#qn .line {
    width: 1px;
    height: 100%;
    background: #000;
    top: 0;
    left: 96%;
    /* transform: translateX(-50%); */
    position: absolute;
    bottom: 10px;
}

#qn .btn.navbtn {
    font-size: 20px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    color: #fff;
    text-transform: capitalize;
    background-color: #000;
    border-radius: 15px;
    padding: 0.5rem 1.5rem;
    text-align: center;
    cursor: pointer;
}

#qn .btn.navbtn:hover {
    background-color: #161616de;
    transition: all 0.3s ease;
}