@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

@font-face {
  font-family: Argentum Sans;
  src: url(./assets/font/ArgentumSans-Medium.ttf);
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

:root {
  --color-white: #fff;
  --black-color: #000;
  --transition: all 400ms ease;
  --light-color: #999;
  --light-grey: #cecece;
  --grey-light: #F0EFF2;
  --dark-color: rgba(var(--black-color), 0.7);
  --container-lg: 82%;
  --container-md: 90%;
  --transition: all 400ms ease;
  --cyan-color: #007f90;
  --light-cyan-color: #00a8c8;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Open Sans', sans-serif, Argentum Sans;
  line-height: 1.5;
  color: var(--dark-color);
  /* user-select: none; */
}

.container {
  width: var(--container-lg);
  margin: 0 auto;
}

img {
  display: block;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
}

section h2 {
  text-align: center;
}

section p {
  text-align: center;
  width: 42%;
  margin: 0.6rem auto 2.5rem;

}

p {
  font-weight: 200;
}

.btn-black {
  min-width: 10rem;
  text-align: center;
  text-transform: capitalize;
  display: inline-block;
  width: fit-content;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
  background: #000;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.2rem;
  cursor: pointer;
  transition: all 400ms ease;
}

.btn-black:hover {
  background: lighten(#000, 8%);
  color: #fff;
  box-shadow: 0 1rem 1.6rem rgba(#000, 0.15);
  transform: scale(0.94);
}


.btn-white {
  min-width: 10rem;
  text-align: center;
  text-transform: capitalize;
  display: inline-block;
  width: fit-content;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
  background: #fff;
  color: #000;
  font-weight: 500;
  letter-spacing: 0.2rem;
  cursor: pointer;
  transition: all 400ms ease;
}

.btn-white:hover {
  background: lighten(#fff, 8%);
  color: #000;
  box-shadow: 0 1rem 1.6rem rgba(#fff, 0.15);
  transform: scale(0.94);
}

.btn3 {
  display: inline-block;
  width: fit-content;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
  background: var(--black-color);
  color: var(--color-white);
  font-weight: 500;
  letter-spacing: 0.2rem;
  cursor: pointer;
  transition: all 400ms ease;
  min-width: 10rem;
  text-align: center;
  text-transform: capitalize;
}

.btn3:hover {
  background: lighten(#000, 8%);
  color: var(--color-white);
  box-shadow: 0 1rem 1.6rem rgba(#000, 0.15);
  transform: scale(0.94);
}

.btn4 {
  display: inline-block;
  width: fit-content;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
  background: var(--color-white);
  color: var(--black-color);
  font-weight: 500;
  letter-spacing: 0.2rem;
  cursor: pointer;
  transition: all 400ms ease;
  min-width: 10rem;
  text-align: center;
  text-transform: capitalize;
}

.btn4:hover {
  background: lighten(#fff, 8%);
  color: var(--black-color);
  box-shadow: 0 1rem 1.6rem rgba(#fff, 0.15);
  transform: scale(0.94);
}


.btn-link {
  display: inline-block;
  color: var(--cyan-color);
  cursor: pointer;
  text-decoration: underline !important;
}

.btn-link:hover {
  color: var(--light-cyan-color);
  transition: all 400ms ease;
}

.qbg {
  background-color: #fff;
  /* border: 2rem solid #000; */
  height: 100vh;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuZGV2L3N2Z2pzIiB2aWV3Qm94PSIwIDAgNzAwIDcwMCIgd2lkdGg9IjcwMCIgaGVpZ2h0PSI3MDAiPjxkZWZzPjxmaWx0ZXIgaWQ9Im5ubm9pc2UtZmlsdGVyIiB4PSItMjAlIiB5PSItMjAlIiB3aWR0aD0iMTQwJSIgaGVpZ2h0PSIxNDAlIiBmaWx0ZXJVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIHByaW1pdGl2ZVVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJsaW5lYXJSR0IiPgoJPGZlVHVyYnVsZW5jZSB0eXBlPSJ0dXJidWxlbmNlIiBiYXNlRnJlcXVlbmN5PSIwLjE3NiIgbnVtT2N0YXZlcz0iNCIgc2VlZD0iMTUiIHN0aXRjaFRpbGVzPSJzdGl0Y2giIHg9IjAlIiB5PSIwJSIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgcmVzdWx0PSJ0dXJidWxlbmNlIj48L2ZlVHVyYnVsZW5jZT4KCTxmZVNwZWN1bGFyTGlnaHRpbmcgc3VyZmFjZVNjYWxlPSI5IiBzcGVjdWxhckNvbnN0YW50PSIwLjgiIHNwZWN1bGFyRXhwb25lbnQ9IjIwIiBsaWdodGluZy1jb2xvcj0iI2ZmZmZmZiIgeD0iMCUiIHk9IjAlIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBpbj0idHVyYnVsZW5jZSIgcmVzdWx0PSJzcGVjdWxhckxpZ2h0aW5nIj4KICAgIAkJPGZlRGlzdGFudExpZ2h0IGF6aW11dGg9IjMiIGVsZXZhdGlvbj0iMTA0Ij48L2ZlRGlzdGFudExpZ2h0PgogIAk8L2ZlU3BlY3VsYXJMaWdodGluZz4KICA8ZmVDb2xvck1hdHJpeCB0eXBlPSJzYXR1cmF0ZSIgdmFsdWVzPSIwIiB4PSIwJSIgeT0iMCUiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGluPSJzcGVjdWxhckxpZ2h0aW5nIiByZXN1bHQ9ImNvbG9ybWF0cml4Ij48L2ZlQ29sb3JNYXRyaXg+CjwvZmlsdGVyPjwvZGVmcz48cmVjdCB3aWR0aD0iNzAwIiBoZWlnaHQ9IjcwMCIgZmlsbD0iI2UyZTJlMmZmIj48L3JlY3Q+PHJlY3Qgd2lkdGg9IjcwMCIgaGVpZ2h0PSI3MDAiIGZpbGw9IiNmZmZmZmYiIGZpbHRlcj0idXJsKCNubm5vaXNlLWZpbHRlcikiPjwvcmVjdD48L3N2Zz4=);
  border-radius: 80px;
  /* max-height: max-content;
    display: flex;
    height: 100vh;
    align-items: center; */
  overflow: hidden;
}

.form-group {
  margin-bottom: 1.2rem;
  padding-top: 0.5rem;
}

.form-group p.note {
  font-size: 0.8rem !important;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

/*============= MEDIA QUERIES (MEDIUM DEVICES) ================*/

@media screen and (max-width:1024px) {
  .container {
    width: 90%;
  }

  section p {
    width: 55%;
  }

}


/*============= MEDIA QUERIES (SMALLER DEVICES) ================*/

@media screen and (max-width:600px) {
  section p {
    width: 90%;
  }

}