#qn .steps {
    padding-right: 4%;
    width: fit-content;
}

#qn .steps .wrapper {
    width: 330px;
    font-size: 14px;
}

#qn .steps ul {
    padding: 0;
    margin: 0;
}

#qn .steps ul li {
    list-style: none;
}

#qn .steps li {
    margin-bottom: 24px;
    position: relative;
}

#qn .steps li.progress-item {
    color: rgba(255, 255, 255, 0.7);
    border: none;
    background-color: transparent;
    outline: none !important;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    min-width: max-content;
    z-index: 1;
}

#qn .steps li::before {
    content: "";
    top: -8px;
    left: -8px;
    width: 55px;
    height: 55px;
    position: absolute;
    border-radius: 100%;
    border: 2px solid rgba(107, 89, 211, 0);
    z-index: 3;
}

#qn .steps li span {
    width: 30px;
    color: #fff;
    height: 30px;
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;
    border-radius: 50%;
    background: #bcbdbd;
    position: relative;
    text-align: center;
    display: inline-block;
    z-index: -1;
}

#qn .steps li span::before {
    content: "";
    width: 2px;
    height: 55px;
    background: #b8b9b9;
    position: absolute;
    right: 15px;
    top: 40px;
}

#qn .steps li span::after {
    content: "";
    width: 2px;
    height: 0;
    background: var(--black-color);
    position: absolute;
    right: 15px;
    top: 40px;
    transition: all 0.6s ease;
}

#qn .steps li.progress-item:last-child span::before,
#qn .steps li.progress-item:last-child span::after {
    height: 0%;
    top: 35px;
}

#qn .steps li.is-done:nth-last-child(2) span::before,
#qn .steps li.is-done:nth-last-child(2) span::after {
    height: 55px;
}

#qn .steps li.is-done::before {
    content: "";
    top: 16px;
    left: -8px;
    width: 40px;
    height: 40px;
    position: absolute;
    border-radius: 100%;
    border: 3px solid var(--black-color);
    transition: top 0.7s ease;
}

#qn .steps li.is-done:last-child::before {
    top: 15px;
}

#qn .steps li:last-child span {
    top: 9px;
}

#qn .steps li.is-done span {
    background: var(--black-color);
}

#qn .steps li.is-done span:after {
    height: 55px;
}

#qn .steps li .text {
    color: black;
    display: block;
    font-size: 15px;
    margin-left: 15px;
    margin-top: 15px;
}

#qn .steps li.progress-item:last-child div.text {
    margin-top: 15px;
}

#qn .steps li p {
    color: #1f1f1f;
    font-size: 14px;
}

#qn .steps li .text>p {
    width: 300px;
}

#qn .steps li strong:nth-child(2) {
    /* Select step number */
    /* margin-left: -5px; */
    /* Adjust margin as needed */
}