.option-selector {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.option-selector div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.option-selector input[type="radio"] {
    margin-right: 10px;
    display: none;
}

.option-selector label {
    font-weight: normal;
    cursor: pointer;
    border-radius: 25px;
    font-size: 15px;
    padding: 0.3rem 1rem;
    border: 1px solid #000;
    margin-top: 1rem;
}

.option-selector div:first-child label {
    margin-top: 0 !important;
}

.option-selector textarea {
    width: 500px;
    height: 80px;
    margin-top: 5px;
}

.option-selector textarea:focus {
    border: 1px solid #000 !important;
}

.option-selector textarea:focus {
    outline: none;
    border-color: #999;
}

/* Show textarea when "Other" is selected */
.option-selector input[type="radio"]:checked[value="other"]~textarea {
    display: block;
}

.option-selector input[type="radio"]+label:hover,
.option-selector input[type="radio"]+div>label:hover {
    background: var(--light-grey);
    transition: all 0.3s ease;
}

.option-selector input[type="radio"]:checked+label,
.option-selector input[type="radio"]:checked+div>label {
    background: var(--light-grey);
}

.option-selector input[type="radio"]:checked+div>label {
    background: var(--light-grey);
}

.option-selector .icon {
    margin-bottom: 0 !important;
}


@keyframes rotate-minus {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(90deg);
    }
}

@keyframes rotate-plus {
    from {
        transform: rotate(180deg);
    }

    to {
        transform: rotate(0deg);
    }
}

.rotate-minus {
    animation: rotate-minus 0.2s forwards;
}

.rotate-plus {
    animation: rotate-plus 0.2s forwards;
}