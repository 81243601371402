.loader {
    width: 100%;
    height: 100vh;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    background: #18181b;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader svg {
    width: 100%;
}

.loading-text {
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    margin-top: 20px;
    text-align: center;
}

@keyframes pulsePing {
    0% {
        opacity: 0.8;
        transform: scale(0.4);
    }

    80% {
        opacity: 0;
        transform: scale(1.2);
    }

    100% {
        opacity: 0;
        transform: scale(2.2);
    }
}

.qlogo {
    width: 80px;
    display: block;
    margin: 0 auto;
    animation: pulsePing 1.5s ease-in-out infinite;
}