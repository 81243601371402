.tooltip-container {
    position: relative;
    display: inline-block;
    /* Allow inline positioning */
}

.tooltip {
    position: absolute;
    bottom: 115%;
    /* Position below the icon */
    left: 50%;
    width: 240px;
    transform: translateX(-50%);
    /* Center horizontally */
    background-color: #fff;
    background: linear-gradient(to top, #c4c5c7 0%, #dcdddf 52%, #ebebeb 100%);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Add slight shadow */
    border-radius: 3px;
    padding: 10px;
    /* Ensure tooltip is above other elements */

    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s, opacity 0.2s ease-in-out;
}

.tooltip-visible {
    visibility: visible;
    opacity: 1;
}

.tooltip-icon {
    cursor: pointer;
}

/* .tooltip,
.tooltip.show {
    opacity: 1;
    transition: all 0.8s ease-in-out;
}*/

.tooltip-title {
    font-weight: bold;
    margin-bottom: 5px;
}

.tooltip-separator {
    border-bottom: 1px solid var(--black-color);
    margin-bottom: 5px;
}

.tooltip-content {
    font-size: 14px;
    line-height: 1.5;
}

.tooltip.tooltip-arrow-down::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 43%;
    /* transform: rotateY(-180deg); */
    background: linear-gradient(to top, #c4c5c7 0%, #dcdddf 52%, #ebebeb 100%);
    width: 0;
    height: 7px;
    height: 0;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-top: 16px solid #c4c5c7;
}

/* translateX(-50%) */