.m-option-selector {
    padding-top: 1rem;
}

.m-option-selector .option-item {
    display: flex;
    align-items: center;
    border: 1px solid var(--light-color);
    padding: 10px;
    border-radius: 0.3rem;
}

.m-option-selector .option-item:hover,
.m-option-selector .option-item.active {
    border: 0.15rem solid var(--black-color);
}

.m-option-selector .option-item input[type="checkbox"] {
    margin-right: 10px;
    /* Adjust spacing between checkbox and label */
    /* opacity: 0; */
    /* Hide the default checkbox */
    /* position: absolute; */
    /* Position it off-screen */
    /* pointer-events: none; */
    cursor: pointer;

    /* width: 3em; */
    /* height: 3rem; */
    accent-color: var(--black-color);
    appearance: none;
    -webkit-appearance: none;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 1rem;
    padding: 0.1rem;
    border-radius: 0.3rem;
    border: 1px solid var(--light-color);

}

.m-option-selector .option-item input[type='checkbox']:checked {
    border: 0.15rem solid var(--black-color);
}

.m-option-selector .option-item label {
    width: 100%;
    height: 100%;
    cursor: pointer;

}

.m-option-selector .option-item input[type="checkbox"]::before {
    content: "";
    width: 0.8em;
    height: 0.8rem;
    clip-path: polygon(12.49% 50.79%, 18.5% 37.5%, 42.25% 61.25%, 88.13% 15.38%, 94.5% 30%, 42.25% 82.25%, 12.49% 50.79%);
    transform: scale(0);
    background-color: #000;
}

.m-option-selector .option-item input[type="checkbox"]:checked::before {
    transform: scale(1);
    border: 0.15rem solid var(--black-color);

}

.m-option-selector .option-item input[type="checkbox"]:hover {
    border: 0.15rem solid var(--black-color);
}

.m-option-selector .animate__fadeIn {
    animation: fadeIn 0.5s;
}